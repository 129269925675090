import { Api } from "../services/api";
import { left, Either, right } from "../shared/Result";
export class SubjectAPI {

    public static async getSubjects() : Promise<Either<Response, any>> {
        const url = "/subject/all"

        const response = await Api.get(url)

        if (response.isLeft()) {
            return left(response.value)
        }

        return right(response.value)

    }

    public static async getActiveSubjects() : Promise<Either<Response, any>> {
        const url = "/subject/active/all"

        const response = await Api.get(url)

        if (response.isLeft()) {
            return left(response.value)
        }

        return right(response.value)

    }
}