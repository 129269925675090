import { useState } from "react"
import { IFlashcardDTO } from "../../utils/services/dtos/flashcardDTO"
import { BackgroundFlashcards } from "./BackgroundFlashcards"
import { FlashcardButtons } from "./Buttons"
import {  Flashcards } from "./Flashcards"



export const MainBody = (props : {state : number, setState: (x:number) => void, flashcards : IFlashcardDTO[]}) => {
    return(
        <div className='h-full w-full  flex flex-col items-center pt-20'>
            <div className='h-[42%] w-full  flex items-center justify-center pt-16'>
                <BackgroundFlashcards />
                <Flashcards state={props.state} flashcards={props.flashcards}/>
            </div>
            <div className='h-[58%] mt-16 w-full'>
                <FlashcardButtons state={props.state} setState={props.setState} flashcards={props.flashcards}/>
            </div>
            
        </div>
    )
}
