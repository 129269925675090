
// export const SubjectHeader = () => {
//     return (
//         <div className='w-full h-full border-b border-opacity-50 border-[#363A4F] bg-darkShade flex justify-around items-center shadow-sm rounded-b-sm'>
//             <h1 className=' text-2xl font-semibold text-[#FFFFFF]'>Matérias</h1>
//         </div>
//     )
// }

export const SubjectHeader = () => {
    return (
        <div className='w-full h-full  '>
            <h1 className=' text-4xl pb-2 font-medium text-darkShade'>Matérias</h1>
            <h2 className=' font-medium text-2xl'>Estude para suas provas usando flashcards</h2>
        </div>
    )
}