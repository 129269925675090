import { useNavigate } from "react-router-dom"
import { SubjectAPI } from "../../utils/domain/Subject"
import { IFlashcardDTO } from "../../utils/services/dtos/flashcardDTO"
import { ISubjectDTO } from "../../utils/services/dtos/subjectDTO"
import {useState, useEffect} from "react"
export const SubjectBody = (props : {subjects : ISubjectDTO[]}) => {
    const navigate = useNavigate()
    
    return(
        <div className='flex flex-col pt-4 h-full gap-2 w-full'>
            {props.subjects?.map((subject : ISubjectDTO) => {
                    return(

                    <button onClick={() => {navigate(`/subject/${subject._id}`)}} className='w-full flex flex-col gap-2 shadow-sm border border-opacity-50 border-[#363A4F] h-24 p-3 justify-between  rounded-sm bg-darkShade'>
                        
                            <p className=' text-gray-100 font-semibold text-center text-2xl'>
                                {subject.name}
                            </p> 
                            {/* <p className=' text-gray-100 font-semibold text-center text-2xl'>Flashcards disponiveis:</p> */}
                        
                    </button>
                    )
                }
                
            )}
       <button onClick={() => {navigate("/performance")}} className=' bg-darkShade rounded-md bottom-6 right-5 px-4 font-semibold text-gray-100 border border-opacity-50 border-[#363A4F] h-12 fixed'>Calculo de MDC</button>

        </div>
        // <div className='h-full grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 grid-rows-5 gap-2 w-full'>
        //     {props.subjects?.map((subject : ISubjectDTO) => {
        //         return(

        //         <button onClick={() => {navigate(`/subject/${subject._id}`)}} className='w-full shadow-sm border border-opacity-50 border-[#363A4F] min-h-32 p-1 items-center flex justify-center rounded-sm bg-darkShade'>
        //             <p className=' text-gray-100 font-semibold text-center text-xl'>
        //                 {subject.name}
        //             </p> 
        //         </button>
        //         )
        //     }
                
        //     )}
        //     <button onClick={() => {navigate("/performance")}} className=' bg-darkShade rounded-md bottom-6 px-4 font-semibold text-gray-100 border border-opacity-50 border-[#363A4F] h-12 fixed'>Calculo de MDC</button>
        // </div>
    )

}