import { Either, left, right } from "../shared/Result"

export class Api {
    static apiUrl : string = "https://api.flashcardsnotas.online"

    public static async get(url : string, token? : string) : Promise<Either<Response, any>> {
        const response = await fetch(this.apiUrl + url, {
            method : "GET",
            headers : {"Authorization" : 'Bearer ' + token }
        })

        const json = await response.json()

        if (response.ok) {
            return right(json)
        }

        return left(response)
    }

    

    public static async post(url : string, body : any, token? : string) : Promise<Either<Response, JSON>>{
        
        const response = await fetch(this.apiUrl + url, {
            method : "POST",
            body : body,
            headers : {"Content-Type" : "application/json", "Accept" : "application/json", "Authorization" : `Bearer ` + token}
        })

        const json = await response.json()

        if (response.ok) {
            return right(json)
        }
        
        return left(response)

    }

}