import { PerformanceButtons } from "./PerformanceButtons"
import { ISubjectDTO } from "../../utils/services/dtos/subjectDTO"
import { useNavigate } from "react-router-dom"
import { SubjectAPI } from "../../utils/domain/Subject"
import {useState, useEffect} from "react"
export const PerformanceBody = () => {
    const navigate = useNavigate()
    const [subjects, setSubjects] = useState<ISubjectDTO[]>([])
    const [selected, setSelected] = useState<string>("none")
    useEffect(() => {
        SubjectAPI.getSubjects().then((response) => {
            if (response.isLeft()) {
                alert("Não foi possível carregar as matérias")
            }else {
                setSubjects(response.value)
            }  
        })
    }, [])
  

    return (
        <div className='h-full p-4 w-full '>

            {/* <select id="subject_select" className='  h-12 font-semibold text-xl max-w-[60%] focus:outline-none ring-0 bg-transparent border-b border-darkShade'> */}
            
            <select onChange={(e) => navigate(`/performance/${e.target.value}`)  } id="subject_select" className=' text-lightShade h-12 text-xl max-w-[65%]  bg-darkerShade rounded-md border-darkShade'>
                <option value="none"  className='bg-darkerShade text-lightShade'>Escolha uma matéria</option>
                {subjects.map((subject) =>{
                    return (
                        <option   className='bg-darkerShade text-lightShade'>{subject.name}</option>
                    )
                })}
            </select>
            
            {/* {selected !== "none"?
            
            <PerformanceButtons subject={selected}/>
            
        : ""} */}

            

            {/* <button className='bg-darkShade rounded-md right-2 px-4 font-semibold mt-2 text-gray-100 border border-opacity-50 border-[#363A4F] h-12 fixed'>Adicionar materia</button> */}

           
            {/* <input type="number" placeholder=""/> */}
        </div>
    )
}
