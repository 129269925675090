import { PerformanceBody } from "../elements/performance/PerformanceBody"
import { PerformanceHeader } from "../elements/performance/PerformanceHeader"
import { ISubjectDTO } from "../utils/services/dtos/subjectDTO"
export const PerformancePage = () => {
    return (
        
            <div className='w-screen h-screen bg-lightShade'>
                <header className='h-[10%]'>
                    <PerformanceHeader/>
                </header>
                <body className="h-[90%] ">
                    <PerformanceBody />
                </body>
            </div>
    
    )
}