import { useNavigate } from "react-router-dom"
// import { navigate } from "../utils/navigate"
export const MainHeader = (props : {counter : number, maxValue : number}) => {
    const navigate = useNavigate()
    
    return (
        <div className='w-full h-full  border-b border-opacity-50 border-[#363A4F] bg-darkShade flex justify-around items-center shadow-sm rounded-b-sm'>
            <h1 className=' text-2xl  font-semibold text-gray-100 '>Flashcards</h1>
            <h1 className='text-2xl w-12 font-semibold text-gray-100'>{(props.maxValue + 1) - props.counter}/{props.maxValue}</h1>
            <button className="font-semibold flex justify-center items-center border-lightShade border-[3px] rounded-sm aspect-square w-[42px] text-2xl text-gray-100" onClick={() => navigate("/")}>X</button>
        </div>
    )
}