import { MainHeader } from "../elements/flashcard/FlashcardHeader"
import { MainBody } from "../elements/flashcard/MainBody"
import { IFlashcardDTO } from "../utils/services/dtos/flashcardDTO"
import { useState, useEffect } from "react"
import { FlashcardAPI } from "../utils/domain/Flashcard"
import { useParams } from "react-router-dom"
import { useGetId } from "../routes"
export const MainPage = () => {
    const [flashcards, setFlashcards] = useState<IFlashcardDTO[]>([])
    const [counter, setCounter] = useState(0)
    const id = useGetId()

    useEffect(() => {
       if (id) {
            FlashcardAPI.getBySubject(id).then((response) => {
                if (response.isLeft()) {
                    alert("Um erro inesperado aconteceu ao encontrar os flashcards [Erro 1]")
                }else {
                    if (response.value.length === 0) {
                        alert("Esta matéria ainda não possui nenhum flashcard")
                    }
                    setCounter(response.value.length)
                    setFlashcards(response.value.reverse())
                    
                }
            })
       }
    }, [])

    
    
    return (
        <div className=' w-screen h-screen bg-lightShade '>
            <header className="h-[10%]">
                <MainHeader counter={counter} maxValue={flashcards.length}/>
            </header>
            <body className='w-full h-[90%]'>
                {flashcards ?
                <MainBody state={counter} setState={setCounter} flashcards={flashcards}/>
: ""}
            </body>
            
        </div>
    )
}