import { IGrades } from "./getGrade"
import { Guard } from "../shared/Guard"
let STORAGE_KEY = "FLASHCARDS_STORAGE"

export async function addToStorage(grades : IGrades, subject: string) {
    const GuardResponse = Guard.againstNullOrUndefinedBulk([grades?.activities.max, grades?.activities.value, grades?.tests.max, grades?.tests.value], "Grades")
    const response = localStorage.getItem(STORAGE_KEY)
    if (GuardResponse.isRight()) {
        if (!response) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify({[subject] : grades}))
        } else {
            const subjects = await JSON.parse(response)
            subjects[subject] = grades
            localStorage.setItem(STORAGE_KEY, JSON.stringify(subjects))
        }
        
    }
    alert("Os dados foram salvos com sucesso")
}