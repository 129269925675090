import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai"
import { IFlashcardDTO } from "../../utils/services/dtos/flashcardDTO"
export const FlashcardButtons = (props :  {state : number, setState: (x:number) => void, flashcards : IFlashcardDTO[]}) => {
    // darkShade : "#5C8984",
    //   lightShade : "#ECE3CE",
    return (
        <>
            <div  className="w-full h-full px-6 items-center flex justify-around ">
                <button onClick={props.state >= 0 && props.state < props.flashcards.length? () => {props.setState(props.state + 1) }: () => ""}  className='w-20 rounded-[100px] flex items-center justify-center aspect-square bg-darkShade' >
                    <AiOutlineArrowLeft color={"#e5e7eb"} className=" w-12 h-12"/>
                </button>
                <button onClick={props.state > 1? () => {props.setState(props.state - 1) }: () => ""} className='w-20 rounded-[100px] flex items-center justify-center aspect-square bg-darkShade'>
                    <AiOutlineArrowRight color={"#e5e7eb"} className=" w-12 h-12"/>
                </button>
            </div>
        </>
    )
}