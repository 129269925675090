import { Route, createBrowserRouter, useParams, BrowserRouter, Routes } from "react-router-dom";
import App from "./App";
import { MainPage } from "./pages/MainPage";
import { SubjectsPage } from "./pages/SubjectsPage";
import { PerformancePage } from "./pages/PerformancePage";
import { SubjectPerformancePage } from "./pages/SubjectPerformancePage";
import { NotFoundPage } from "./pages/404Page";
import { JSXElementConstructor } from "react";
export function useGetId() {
    let {id} = useParams()

    if (!id) {
        alert("Um erro inesperado aconteceu ao encontrar os flashcards [Erro 2]")
    }else {
        return(id)
    }
}

export function useGetSubject() {
    let {subject} = useParams()
    if (!subject) {
        alert("Um erro inesperado aconteceu")
    }else {
        return subject
    }
}

const router = createBrowserRouter([
    // <Router>
        // <BrowserRouter>
        //     <Routes>

        
        //         <Route element={MainPage} path="/subject/:id" />
        //         <Route path="/" element={<SubjectsPage/>}/>

        //     </Routes>

        // </BrowserRouter>
    // </Router>
    {path : '/subject/:id',
    element : <MainPage/>},
    {
    path : "/",
    element: <SubjectsPage/>
    },
    {path : "/performance",
    element : <PerformancePage/>},
    {path : "/performance/:subject",
        element : <SubjectPerformancePage/>},

    {path : "/*",
        element : <NotFoundPage/>
    }
    

])

export default router