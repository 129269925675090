import { Left, left, right } from "./Result"
export interface IGuardArgument {
    argument: any;

  }

export class Guard {
    public static againstNullOrUndefined(argument : any, argumentName: string) {
        
        if (argument === null || argument === undefined || argument === "" ) {
            return left(
                {errorMessage: `${argumentName} is null or undefined`,
                location: `${Guard.name}.${this.againstNullOrUndefined.name}`}
            )
        }
        return right(true)

    }

    public static againstNullOrUndefinedBulk(args : any[], argumentName : string) {
      // const or let?
   
      for (const arg of args) {
        
        const result = this.againstNullOrUndefined(arg, argumentName)
        if (result.isLeft()) {
          return left(
            result.value
          )
        }
      }
      return right(true)
    }
}