import { useNavigate } from "react-router-dom"
import { ErrorButtons } from "./errorButtons"
export const ErrorBody = () => {
    const navigate = useNavigate()
    return (
        <div className='w-[80%]  font-semibold flex flex-col h-[40%] '>
            <h1 className='text-5xl'>
                Ops, algo deu errado!
            </h1>
            <h2 className='text-2xl'>
                Por favor, tente novamente.
            </h2>
            
            <button onClick={() => navigate('/')} className='mt-4 rounded-sm h-16 w-full sm:w-[50%] md:w-[30%] bg-darkShade text-gray-100'>Ir para a página inicial</button>
            
        </div>

    )
}