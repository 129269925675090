import { SubjectBody } from "../elements/subject/SubjectBody"
import { SubjectHeader } from "../elements/subject/SubjectHeader"
import {useState, useEffect} from "react"
import { SubjectAPI } from "../utils/domain/Subject"
import { ISubjectDTO } from "../utils/services/dtos/subjectDTO"
export const SubjectsPage = () => {
    const [subjects, setSubjects] = useState<ISubjectDTO[]>([])
    
    useEffect(() => {
        SubjectAPI.getActiveSubjects().then((response) => {
            if (response.isLeft()) {
                alert("Não foi possível carregar as matérias")
            }else {
                setSubjects(response.value)
            }  
        })
    }, [])

    return (
        
            <div className='w-screen  h-screen bg-lightShade'>
                <div className='w-full h-full px-5 pt-8'>
                    <header className='h-[15%] '>
                        <SubjectHeader/>
                    </header>
                    <body className="h-[85%] ">
                        <SubjectBody subjects={subjects}/>
                    </body>
                </div>
            </div>
    
    )
}