
import {useState} from "react"
import { TbHandFinger } from "react-icons/tb"
import { IFlashcardDTO } from "../../utils/services/dtos/flashcardDTO"
export const Flashcard = (props : {flashcard : IFlashcardDTO, state : number, index: number}) => {
    const [flipped, setFlipped] = useState(false)
    const values = {answer : props.flashcard.answer.text, question : props.flashcard.question.text}
    const [displayMessage, setDisplayMessage] = useState(props.flashcard.question.text)
    // console.log(props.flashcard.answer.text)
    // console.log(props.flashcard.question.text)

    return (
        <>
            <div onClick={flipped === false? () => (setFlipped(true), setTimeout(() => setDisplayMessage(props.flashcard.answer.text), 84)) : () => (setFlipped(false), setTimeout(() => setDisplayMessage(props.flashcard.question.text), 84))} className={` ${flipped === true? "transform -scale-x-100" : ""}  ${props.state > props.index? "" : ` translate-x-[-400px] sm:translate-x-[-900px]  lg:translate-x-[-1920px] duration-[85ms]`} w-[80%] z-10 border border-opacity-50 border-[#363A4F] flex justify-center items-center  hover:invert-0 duration-300 bg-darkShade rounded-md h-[50%] absolute`}>
                <p style={displayMessage === props.flashcard.answer.text? {transform: "rotateY(180deg)"} : {}} className=" text-gray-100 text-xl sm:text-2xl px-6 font-semibold">{flipped === false? "Pergunta: " + values.question : "Resposta: " + values.answer}</p>
                
                <div style={displayMessage === props.flashcard.answer.text? {transform: "rotateY(180deg)"} : {}} className={displayMessage === props.flashcard.answer.text? 'absolute left-0 bottom-0 p-4 flex' : 'absolute right-0 bottom-0 p-4 flex'}>
                    <span className=' text-gray-100'>Pressione para virar&nbsp;</span>
                    <TbHandFinger color={"#f3f4f6"} size={24}/>
                </div>
                
                
                
            </div>
        </>
    )
}
