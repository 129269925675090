import { Api } from "../services/api"
import { Either, left, right } from "../shared/Result"
export class FlashcardAPI {

    public static async getBySubject(subjectId : string) : Promise<Either<Response, any>> {
        const url = `/subject/${subjectId}/flashcards`

        const response = await Api.get(url)

        if (response.isLeft()) {
            return left(response.value)
        }

        return right(response.value)
    }
    

}