import { PerformanceBody } from "../elements/performance/PerformanceBody"
import { PerformanceHeader } from "../elements/performance/PerformanceHeader"
import { ISubjectDTO } from "../utils/services/dtos/subjectDTO"
import { SubjectPerformanceBody } from "../elements/performance/SubjectPerformanceBody"
import { useParams } from "react-router-dom"
import { useGetSubject } from "../routes"
import { PerformanceSubjectHeader } from "../elements/performance/PerfomanceSubjectHeader"
export const SubjectPerformancePage = () => {
    const subject = useGetSubject()
    
    return (
        
            <div className='w-screen h-screen bg-lightShade'>
                <header className='h-[10%]'>
                    <PerformanceSubjectHeader/>
                </header>
                <body className="h-[90%] ">
                    <SubjectPerformanceBody subject={subject? subject : "SEM MATERIA"} />
                </body>
            </div>
    
    )
}