import { Guard } from "../shared/Guard"

export interface IGrade {
    value : number,
    max : number
}

export interface IGrades {
    activities : IGrade,
    tests : IGrade,
    prediction : number
}


// setGrade changes depending on id

export function getPassingGrade(value: string, id : string, type : string, setState : (grade : IGrades) => void, state : IGrades, setGrade : (grade : number) => void, setPredictionGrade : (grade : number) => void) {
    if (Number.isNaN(Number(value))) {
        return
    }
        if (id === "activities" || id === "tests") {
            if (type === "value" || type === "max") {
                console.log(Number('23.4'))
                state[id][type] = Number(value.replace(',', '.'))
                setState(state)
                console.log(state[id][type])
                console.log(state)
                
                const number = ((state.activities.value / state.activities.max) * 40) + (((state.tests.value + state.prediction)  / (state.tests.max + 10)) * 60)

                setPredictionGrade(number)
                setGrade((60 - (( state.activities.value/state.activities.max * 40 )))/60 *(state.tests.max + 10) - state.tests.value)
                
            }
        }   
        if (id === "prediction") {
            state[id] = Number(value.replace(',', '.'))
            const SecondGuardResponse = Guard.againstNullOrUndefinedBulk([state.activities.max, state.activities.value, state.tests.max, state.tests.value, state.prediction], "Grades")
            setState(state)
            if (SecondGuardResponse.isRight()) {
                console.log(state.prediction)

                const number = ((state.activities.value  / state.activities.max) * 40) + (((state.tests.value + state.prediction)  / (state.tests.max + 10)) * 60)
                setPredictionGrade(number)
            } 
        }
    
}

// export function predictGrade(state : IGrades, setState : (grade : number) => void) {
//     state[]
//     const number = ((state.activities.value  / state.activities.max) * 40) +  ((state.tests.value + state.prediction.value  / state.tests.max + 10) * 60)

//     setState(number)
// }