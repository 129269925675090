import { useEffect, useState } from "react"
import {TbHandFinger} from "react-icons/tb"
import { IFlashcardDTO } from "../../utils/services/dtos/flashcardDTO"
import { Flashcard } from "./Flashcard"
// TODO: add  image support


export const Flashcards = ( props : {state : number, flashcards : IFlashcardDTO[]} ) => {
    console.log(props.state, props.flashcards.length, "STATE")
    let test
    useEffect(() => {
        test = props.flashcards
    }, [])
    return(
        <>
        {/* props.state > index? */}
        {test !== props.flashcards? props.flashcards.reverse().map((flashcard, index) => {
            console.log(props.state)
            console.log(props.flashcards.reverse()[props.state])

            return(
                
                <Flashcard flashcard={flashcard} index={index} state={props.state}/>
                // <div className={ ` ${props.state > index? "" : ` translate-x-[-400px] sm:translate-x-[-800px]  lg:translate-x-[-1920px] duration-75`} w-[80%] z-10 border-gray-800 border flex justify-center items-center shadow-lg  hover:invert-0 duration-300 bg-darkShade rounded-md h-[50%] absolute` }>
                //     {/* <p className=" text-gray-100 text-2xl px-12 font-semibold">{flashcard?.question}</p> */}
                   
                //     <div  className={ 'absolute left-0 bottom-0 p-4 flex'}>
                //         <span className=' text-gray-100'>Pressione para virar&nbsp;</span>
                //         <TbHandFinger color={"#f3f4f6"} size={24}/>
                //     </div>
                
                // </div>

            )
        }) : "" }
       
        
            
        </>
    )

}

