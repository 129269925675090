let STORAGE_KEY = "FLASHCARDS_STORAGE"

export async function getStorage(setPassingGrade : (grade : number) => void, setPredictionGrade  : (grade : number) => void, setGrades : (grade : any) => void, subject : string) {
    const storage = localStorage.getItem(STORAGE_KEY)
    if (storage) {
        
        const response = await JSON.parse(storage)
        // console.log(response[subject])
        if (response[subject]) {
            setGrades(response[subject])
            setPassingGrade((60 - (( response[subject].activities.value/response[subject].activities.max * 40 )))/60 *(response[subject].tests.max + 10) - response[subject].tests.value)
            if (response[subject].prediction) {
                setPredictionGrade(((response[subject].activities.value  / response[subject].activities.max) * 40) + (((response[subject].tests.value + response[subject].prediction)  / (response[subject].tests.max + 10)) * 60))
            }

            return
        }
    }
    setPassingGrade(10)
    setPredictionGrade(0)
    setGrades({activities : {value : 0, max : 0}, tests : {value : 0, max : 0}, prediction : 0})
}

