import { useEffect, useState } from "react"
import { IGrades } from "../../utils/functions/getGrade"
import { getPassingGrade } from "../../utils/functions/getGrade"
import { Guard } from "../../utils/shared/Guard"
import { getStorage } from "../../utils/functions/storageFunctions"
import { addToStorage } from "../../utils/functions/addToStorage"
import { ReactDOM } from "react"
import { IndexKind } from "typescript"
let STORAGE_KEY = "FLASHCARDS_STORAGE"

export const PerformanceButtons = (props : {subject : string}) => {

    const [grades, setGrades] = useState<any>()
    const [passingGrade, setPassingGrade] = useState(0)
    const [predictionGrade, setPredictionGrade] = useState(0)
    useEffect(() => {

        getStorage(setPassingGrade, setPredictionGrade, setGrades, props.subject)
        // .then(() => {
        //     const obj = document.getElementById('activities.value') as HTMLInputElement
        // obj.defaultValue = grades?.activities.value
        // console.log(grades?.activities.value)
        // })
    }, [props.subject])

    // useEffect(() => {
    //     const obj = document.getElementById('activities.value') as HTMLInputElement
    //     obj.value = grades?.activities.value
    //     console.log(grades?.activities.value)
    // }, [props.subject])

    

    // const [predictionGrade, setPredictionGrade] = useState()
    return(
        <div className='w-full h-[80%]  justify-between flex flex-col'>
            <div className='w-full flex flex-col pt-2'>
                <p className='text-3xl sm:text-4xl font-semibold  text-darkerShade'>Nota atual:</p>
                <div className='w-full flex gap-2 flex-row'>
                    <div className=' w-[75%] min-w-[75%] flex flex-row h-[53px] border-2 border-opacity-40 rounded-md border-darkShade'>
                        <div style={{width : (grades?.activities.value / grades?.activities.max * 40) + '%'}} className=' max-w-[40%] h-full bg-[#0070CC]'></div>
                        <div style={{width : (grades?.tests.value / grades?.tests.max * 60) + '%'}} className=' h-full max-w-[60%] bg-[#29D1C8]'></div>
                    </div>
                    <div className=' flex-1 flex justify-center items-center h-[53px] border-2 rounded-md border-opacity-40 border-darkShade '>
                        <p className='font-semibold text-lg text-darkShade'>{((grades?.tests.value / grades?.tests.max * 60) + (grades?.activities.value / grades?.activities.max * 40)).toFixed(2).replace('.', ',')}%</p>
                    </div>
                </div>
                <div className='flex items-center flex-row'>
                {/* <div className='w-6 aspect-square h-6 bg-[#0070CC]'></div> */}
                <div className='w-6 aspect-square my-1 h-6 rounded-sm bg-[#0070CC]'></div>
                    <p className='text-3xl sm:text-4xl font-semibold  text-darkerShade'>&nbsp;Processual</p>
                    
                </div>
            <div className='flex flex-row  '>
                <p className='text-2xl sm:text-3xl text-darkerShade'>Nota atual:&nbsp;</p>
                <input id="activities.value" defaultValue={grades?.activities.value} onChange={(e) => {getPassingGrade(e.target.value.replace(',', '.'), e.target.id.split('.')[0], e.target.id.split('.')[1], setGrades, grades, setPassingGrade, setPredictionGrade)}} className='w-8 h-8 text-darkShade flex text-center bg-transparent rounded-lg border-gray-500 border-2 border-opacity-30  outline-none ring-0' placeholder="0"></input>
                <p className='text-2xl sm:text-3xl text-darkerShade'>&nbsp;de&nbsp;</p>
                <input id="activities.max" defaultValue={grades?.activities.max} onChange={(e) => {getPassingGrade(e.target.value, e.target.id.split('.')[0], e.target.id.split('.')[1], setGrades, grades, setPassingGrade, setPredictionGrade)}} className='w-8 h-8 text-darkShade flex text-center bg-transparent  rounded-lg border-gray-500 border-2 border-opacity-30 outline-none ring-0' type="text" placeholder="0"></input>
                <p className='text-2xl sm:text-3xl text-darkerShade'>&nbsp;pontos</p>
            </div>

            <div className='flex items-center my-1 flex-row'>
                {/* <div className='w-6 aspect-square h-6 bg-[#0070CC]'></div> */}
                <div className='w-6 aspect-square h-6 rounded-sm bg-[#29D1C8]'></div>
                    <p className='text-3xl sm:text-4xl font-semibold  text-darkerShade'>&nbsp;Síntese</p>
                    
                </div>
            <div className='flex flex-row pb-2'>
                <p className='text-2xl sm:text-3xl text-darkerShade'>Nota atual:&nbsp;</p>
                <input id="tests.value" defaultValue={grades?.tests.value} onChange={(e) => {getPassingGrade(e.target.value.replace(',', '.'), e.target.id.split('.')[0], e.target.id.split('.')[1], setGrades, grades, setPassingGrade, setPredictionGrade)}} className='w-8 h-8 text-darkShade flex text-center bg-transparent  rounded-lg border-gray-500 border-2 border-opacity-30 outline-none ring-0' type="text" placeholder="0"></input>

                <p className='text-2xl sm:text-3xl text-darkerShade'>&nbsp;de&nbsp;</p>
                <input id="tests.max" defaultValue={ grades?.tests.max} onChange={(e) => {getPassingGrade(e.target.value.replace(',', '.'), e.target.id.split('.')[0], e.target.id.split('.')[1], setGrades, grades, setPassingGrade, setPredictionGrade)}} className='w-8 h-8 text-darkShade flex text-center bg-transparent  rounded-lg border-gray-500 border-2 border-opacity-30 outline-none ring-0' type="text" placeholder="0"></input>
                <p className='text-2xl sm:text-3xl text-darkerShade'>&nbsp;pontos</p>
                
            </div>
            <p className='text-base sm:text-2xl text-darkerShade'>Nota necessária em síntese para atingir 60%: {isFinite(passingGrade)? (passingGrade.toFixed(1)).replace('.', ",") : ""}/10</p>

            <p className='text-3xl sm:text-4xl border-t-2  border-darkShade font-semibold  text-darkerShade'>Prever resultado de síntese:</p>

            
            {/* <p className='text-2xl flex flex-row sm:text-3xl text-darkerShade'>Sua nota atual é:&nbsp;<p className="font-semibold">{((grades?.tests.value / grades?.tests.max * 60) + (grades?.activities.value / grades?.activities.max * 40)).toFixed(2).replace('.', ',')}%</p></p> */}
                <div className='flex flex-row'>
                <p className='text-xl sm:text-3xl text-darkerShade'>Caso voce tire:&nbsp;</p>
               
                    {/* <p></p> */}
                    <input className='w-8 h-8 text-darkShade flex text-center bg-transparent rounded-lg border-gray-500 border-2 border-opacity-30 outline-none ring-0' id="prediction.value" defaultValue={grades?.prediction} onChange={(e) => {getPassingGrade(e.target.value.replace(',', '.'), e.target.id.split('.')[0], e.target.id.split('.')[1], setGrades, grades, setPassingGrade, setPredictionGrade)}}  type="text" placeholder="0"/>

                    <p className='text-xl sm:text-3xl text-darkerShade'>&nbsp;de 10 pontos</p>
                    {/* <input id="prediction.max" defaultValue={grades.prediction.max} onChange={(e) => {get(e.target.value.replace(',', '.'), e.target.id.split('.')[0], e.target.id.split('.')[1], setGrades, grades, setPassingGrade, setPredictionGrade)}} className='w-8 h-8 text-darkShade flex text-center bg-transparent  rounded-sm border-darkShade border-b-2 outline-none ring-0' type="number" placeholder="0"></input>
                    <p className='text-2xl  text-darkerShade'>&nbsp;pontos</p> */}
               

                </div>
                <p className='text-xl sm:text-3xl text-darkerShade'>Sua nota será: {predictionGrade.toFixed(2).replace('.', ',')}%</p>
                {/*   <button className='bg-darkShade rounded-md px-2 font-semibold text-gray-100 border border-opacity-50 text-base text-center border-[#363A4F] h-8 '>Gerar</button> */}
                {/* {grades.prediction.max > 0 && grades.prediction.value > 0? 
                <p className='text-2xl text-darkerShade'>Sua nota sera:</p> : ""
                } */}
                
            </div>
            
           
            <button onClick={() => {addToStorage(grades, props.subject)}} className="w-full shadow-sm border border-opacity-50 border-[#363A4F] min-h-22 h-[10%] items-center flex justify-center rounded-sm text-gray-100 bg-darkShade">Salvar</button>
               

        </div>
    )
    
}